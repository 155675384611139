import React from "react";

const Header = () => (
  <header class="masthead masthead-full text-white">
    <div class="container h-100">
      <div class="row h-100 align-items-center">
        <div class="col text-center">
          <div class="masthead-title text-uppercase">Business Debit Card</div>
          <a target="_blank" href="https://cards.zenreki.finance/" class="btn-link">
            <button className="mx-2 mb-2 mt-2 btn btn-primary btn-lg">Get a Card</button>
          </a>
          <div class="masthead-subtitle text-warning">Coming soon...
            <span class="masthead-subtitle text-info">get paid daily with instant-pay</span>
          </div>
          <hr class="primary large"></hr>
          <div class="masthead-subtitle text-primary">portfolio of products</div>
          <a class="logo-link" target="_blank" href="https://zenreki.systems" data-toggle="tooltip" data-placement="top" title="Zenreki.Network">
            <img class="logo-link" src="img/logo-systems.png" alt="Zenreki.Systems"></img>
          </a>
          <a class="logo-link" target="_blank" href="https://zenreki.cash" data-toggle="tooltip" data-placement="top"
            title="Zenreki.Cash">
            <img class="logo-link" src="img/logo-cash.png" alt="Zenreki.Cash"></img>
          </a>
          <a class="logo-link" target="_blank" href="https://zenreki.social" data-toggle="tooltip" data-placement="top"
            title="Zenreki.Social">
            <img class="logo-link" src="img/logo-social.png" alt="Zenreki.Social"></img>
          </a>
          <a class="logo-link" target="_blank" href="https://zenreki.tech" data-toggle="tooltip" data-placement="top"
            title="Zenreki.Tech">
            <img class="logo-link" src="img/logo-tech.png" alt="Zenreki.Tech"></img>
          </a>
        </div>
      </div>
    </div>
    <div class="masthead-scroll">
      <a href="#about" class="masthead-scroll-btn js-scroll-trigger">
        <i class="fas fa-angle-down"></i>
      </a>
    </div>
  </header>
);

export default Header;
