import app from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_ID,
  appMeasurementId: process.env.REACT_APP_MEASUREMENT_ID
};

class FirebaseDatabase {
  constructor() {
    app.initializeApp(config);

    /* Helper */

    this.fieldValue = app.firestore.FieldValue;
    this.emailAuthProvider = app.auth.EmailAuthProvider;
    
    /* Firebase APIs */

    this.auth = app.auth();
    this.db = app.firestore();
    
  }

  // *** Auth API ***

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

  doSendEmailVerification = () =>
    this.auth.currentUser.sendEmailVerification({
      url: process.env.REACT_APP_CONFIRMATION_EMAIL_REDIRECT
    });

  doPasswordUpdate = password => this.auth.currentUser.updatePassword(password);

  deleteMessages = (userId) => 
    this.db.collection("messages").where('userId', '==', userId).get()
    .then(function(querySnapshot) {
      var batch = app.firestore().batch();
      querySnapshot.forEach(function(doc) {
        batch.delete(doc.ref);
      });
      return batch.commit();
    });

  // *** Merge Auth and DB User API *** //

  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged(authUser => {
      if (authUser) {
        this.user(authUser.uid)
          .get()
          .then(snapshot => {
            const dbUser = snapshot.data();

            // default empty roles
            if (!dbUser.roles) {
              dbUser.roles = {};
            }

            // merge auth and db user
            authUser = {
              uid: authUser.uid,
              email: authUser.email,
              emailVerified: authUser.emailVerified,
              providerData: authUser.providerData,
              ...dbUser
            };

            next(authUser);
          });
      } else {
        fallback();
      }
    });

  // *** COIN API ***
      
  fetchToken = async (uid) => {
    let token = null;
    let doc = await this.db.collection("token").doc(uid).get();
    if (doc.exists) {
      token = doc.data();
    }
    return token;
  }  
  
  fetchCoin = async (uid) => {
    let coin = null;
    let docCoin = await this.db.collection("coins").doc(uid).get();
    if (docCoin.exists) {
      coin = docCoin.data();
      if (coin.owner != null) {
        let docOwner = await this.db.collection("owners").doc(coin.owner).get();
        if (docOwner.exists) {
          coin.owner = docOwner.data();
        } else {
          console.log("Owner not found!!!");
        }
      } else {
        console.log("Owner not set");
      }
    } else {
      console.log("Coin not found!!!")
    }
    return coin;
  }

  fetchCoins = async (product, orderby, limit) => {
    let resultsPromise = [];
    let coinsList = await this.db.collection("coins")
      .where("coin_product", "==", product)
      .orderBy(orderby)
      .limit(limit)
      .get();
    for(const coinDoc of coinsList.docs) {
      let coin = await this.coin(coinDoc.id).get();
      resultsPromise.push({ ...coin.data(), uid: coin.id });
    }
    return resultsPromise;
  }

  fetchDripPlans = async (bullion_type) => {
    let resultsPromise = [];
    let plansList = null;
    if (bullion_type == null) {
      plansList = await this.db.collection("drip").get();
    } else {
      plansList = await this.db.collection("drip")
      .where("bullion_type", "==", bullion_type)
      .get();
    }
    for(const planDoc of plansList.docs) {
      resultsPromise.push({ ...planDoc.data(), uid: planDoc.id });
    }
    return resultsPromise;
  }

  coin = uid => this.db
    .doc(`coins/${uid}`);

  coin_product = () => this.db
    .collection("coin_product");
  
}

export default FirebaseDatabase;
