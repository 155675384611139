import React from "react";
import { compose } from "recompose";
import { Link } from "react-router-dom";

import * as ROUTES from "../../constants/routes";

const TokensPage = () => (
  <div>
    <ul className="nav nav-pills nav-fill border-bottom mb-2">
      <li className="nav-item font-weight-bold">
        <Link className="nav-link active" to={ROUTES.TOKEN}>Tokens</Link>
      </li>
    </ul>
  </div>
);

export default TokensPage;
