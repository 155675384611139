import React from "react";
import { Link, NavLink } from "react-router-dom";

import { AuthUserContext } from "../Session";
import SignOutButton from "../SignOutButton";
import * as ROUTES from "../../constants/routes";
import * as ROLES from "../../constants/roles";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as IconsFreeSolid from '@fortawesome/fontawesome-free-solid';
import { useLocation } from "react-router-dom";

const Navigation = () => (
  <AuthUserContext.Consumer>
    {(authUser) =>
      authUser ? <NavigationAuth authUser={authUser} /> : <NavigationNonAuth />
    }
  </AuthUserContext.Consumer>
);

const HomeLogo = () => (
  <div>
    <a
      className="navbar-brand js-scroll-trigger"
      href="/"
    >
      <div className="col text-center">
        <img
          className="navbar-brand-logo"
          src="../../img/logo-zenreki-finance-left.png"
          alt="Zenreki Finance"
        ></img>
      </div>
    </a>
  </div>
);

const HamburgerMenu = () => (
  <button
    className="navbar-toggler"
    type="button"
    data-toggle="collapse"
    data-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent"
    aria-expanded="false"
    aria-label="Toggle navigation"
    style={{ color: "#ffbf00" }}
  >
    <FontAwesomeIcon icon="bars" />
  </button>
);

const NavigationNonAuth = () => (
  <nav
    className="navbar navbar-expand-lg navbar-dark fixed-top"
    id="navbar-main"
  >
    <div className="container">
      <HomeLogo />
      <HamburgerMenu />
      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav ml-auto">
          <li className="nav-item">
            <div class="masthead-scroll">
              <a target="_blank" href="https://cards.zenreki.finance/" class="nav-link">Debit Card Login</a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </nav>
);

const NavigationAuth = ({ authUser }) => (
  <nav
    className="navbar navbar-expand-lg navbar-dark fixed-top"
    id="navbar-main"
  >
    <div className="container">
      <HomeLogo />
      <HamburgerMenu />
      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav ml-auto">
          <li className="nav-item">
            <NavLink
              exact
              className="nav-link js-scroll-trigger"
              activeClassName="active-nav"
              to={ROUTES.HOME}
              data-toggle="collapse"
              data-target=".navbar-collapse"
            >
              Home
            </NavLink>
          </li>
          <SignOutButton />
        </ul>
      </div>
    </div>
  </nav>
);

export default Navigation;
