import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import Navigation from "../Navigation";
import HomePage from "../HomePage";
import TokenPage from "../Tokens";
import TokenDetail from "../Tokens/TokenDetails";

import * as ROUTES from "../../constants/routes";

const App = () => (
  <Router>
    <Navigation />
    <Route exact path={ROUTES.HOME} component={HomePage} />
    <Route exact path={ROUTES.INVEST} component={HomePage} />
    <Route exact path={ROUTES.INVEST_ROUND_1} component={HomePage} />
    <Route exact path={ROUTES.INVEST_ROUND_2} component={HomePage} />
    <Route exact path={ROUTES.TOKEN} component={TokenPage} />
    <Route exact path={ROUTES.TOKEN_DETAIL} component={TokenDetail} />
  </Router>
);

export default App;
