import React from "react";

const Footer = () => (
    
    <footer class="footer text-center text-white-50">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 mb-5 mb-lg-0">
                    <h5 class="text-uppercase">
                        <i class="fas fa-mobile-alt fa-fw"></i>Call
                    </h5>
                    <a class="text-white-50" href="#">1 (201) 331-9138</a>
                </div>
                <div class="col-lg-4 mb-5 mb-lg-0">
                    <h5 class="text-uppercase">
                        <i class="fas fa-map-marker-alt fa-fw"></i>Visit
                    </h5>
                    <div>
                        2701 N Thanksgiving Way #100
                        <br/>
                        Lehi, UT 84043
                    </div>
                </div>
                <div class="col-lg-4">
                    <h5 class="text-uppercase">
                        <i class="fas fa-envelope fa-fw"></i>Email
                    </h5>
                    <a class="text-white-50" href="mailto:mail@example.com">support@zenreki.zendesk.com</a>
                </div>
            </div>
            <div class="footer-social">
                <a class="px-3 text-white-50" href="#">
                <i class="fab fa-facebook-f fa-2x fa-fw"></i>
                </a>
                <a class="px-3 text-white-50" href="#">
                <i class="fab fa-twitter fa-2x fa-fw"></i>
                </a>
                <a class="px-3 text-white-50" href="#">
                <i class="fab fa-linkedin-in fa-2x fa-fw"></i>
                </a>
            </div>
            <div class="footer-copyright">
                &copy; 2021 Zenreki Systems, Inc.
            </div>
        </div>
    </footer>
);

export default Footer;
