import React, { Component } from "react";

import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


import { withFirebase } from "../Firebase";
import * as ROUTES from "../../constants/routes";

import moment from "moment";

const INITIAL_STATE = {
  loading: true,
  token: null,
};

class TokenDetails extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  componentDidMount() {
    this.fetch();
  }

  fetch = async (uid) => {
    let token = await this.props.firebase.fetchToken(this.props.match.params.id);
    console.log(token);

    this.setState({
      token,
      loading: false,
    });
  }
  
  componentWillUnmount() {
  }
  
  render() {
    const { token, loading } = this.state;
    if (token === null) {
      return (
        <div className="container">Loading ...</div>
      )
    } else {
      return (
        <div className="container">
            <dl className="border-bottom" key={token.id}>
              <dt>
                <span>{token.name}</span>
              </dt>
              <span>${token.value}</span>
            </dl>
        </div>
      );        
    }
  }
}

export default withFirebase(TokenDetails);
