import React from "react";

const About = () => (
  <section class="page-section text-center" id="about">
    <div class="container-fluid">
      <h1 class="page-section-heading text-uppercase">Stay in control</h1>
      <p class="lead">With virtual card features</p>
      <hr class="primary small mb-5"></hr>
      <div class="row">
        <div class="col-md-6 col-xl-3 px-md-5 mb-4 mb-xl-0">
          <i class="fas fa-hand-holding-usd fa-4x mb-3"></i>
          <h4 class="text-uppercase text-muted">Control Subscriptions</h4>
          <p>Use virtual card for each subscription.</p>
        </div>
        <div class="col-md-6 col-xl-3 px-md-5 mb-4 mb-xl-0">
          <i class="fas fa-credit-card fa-4x mb-3"></i>
          <h4 class="text-uppercase text-muted">Control Expenses</h4>
          <p>Set card limits &amp; maximums.</p>
        </div>
        <div class="col-md-6 col-xl-3 px-md-5 mb-4 mb-xl-0">
          <i class="fas fa-piggy-bank fa-4x mb-3"></i>
          <h4 class="text-uppercase text-muted">Track &amp; Budget</h4>
          <p>Assign budget categories &amp; view reports.</p>
        </div>
        <div class="col-md-6 col-xl-3 px-md-5 mb-4 mb-xl-0">
          <i class="fas fa-university fa-4x mb-3"></i>
          <h4 class="text-uppercase text-muted">Transfer Funds</h4>
          <p>Using existing bank accounts.</p>
        </div>
      </div>
    </div>
  </section>
);

export default About;
